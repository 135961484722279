export const MANAGE_USERS = 'Manage Users';
export const MANAGE_USER_GROUPS = 'Manage User Groups';
export const MANAGE_PARTNERS = 'Manage Partners';
export const MANAGE_BATCHES = 'Manage Batches';
export const MANAGE_HOST_COMPANY_DETAILS = 'Manage Host Company Details';
export const MANAGE_INTERNS_DETAILS = 'Manage Intern Details';
export const APPROVE_REJECT_HOST_COMPANY = 'Approve/Reject Host Company';
export const MANAGE_INTERN_INTERVIEWS = 'Manage Intern Interviews';
export const SIGNUP_AND_LOGIN_SETTINGS = 'Sign-up and login settings';
export const ASSIGN_AE = 'Assign AEs';
export const MANAGE_WEEKLY_FEEDBACK_IEM = 'Manage Weekly Feedback (IEM)';
export const MANAGE_WEEKLY_FEEDBACK_CAM = 'Manage Weekly Feedback (CAM)';
export const MANAGE_PLACEMENTS = 'Manage Placements';
export const MANAGE_INTERNSHIP = 'Manage Internship';
export const EXTERNAL_USER_READ = 'External User READ Access';
export const EXTERNAL_USER_WRITE = 'External User WRITE Access';
export const INTERN_DELETE = 'Intern Account Delete Utility';
export const HC_DELETE = 'Hc Account Delete Utility';
export const PARTNER_DELETE = 'Delete Partner User';
export const REASONS_LIST = 'Reason Lists';
export const UPDATE_LEARNER_STATUS = 'Update Learner Status';
export const PUSH_MESSAGE_TO_QUEUE = 'Push Message to Queue';
export const UPDATE_WHITELIST = 'Update Whitelist';
