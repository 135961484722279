import { createTheme } from '@mui/material';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#00b1aa',
      contrastText: '#fff',
    },
    secondary: {
      main: '#3aafa933',
      contrastText: '#38ABA5',
    },
    shortcut: {
      main: '#484848',
      contrastText: '#fff',
    },
    success: {
      main: '#52C41A',
    },
    warning: {
      main: '#FAAD14',
    },
  },
  typography: {
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif',
    h1: {
      fontSize: '26px',
      fontWeight: 590,
      lineHeight: '32px',
    },
    h2: {
      fontSize: '22px',
      fontWeight: 590,
      lineHeight: '26px',
    },
    h3: {
      fontSize: '17px',
      fontWeight: 590,
      lineHeight: '22px',
    },
    h4: {
      fontSize: '15px',
      fontWeight: 510,
      lineHeight: '20px',
    },
    headline: {
      fontSize: '13px',
      fontWeight: 510,
      lineHeight: '16px',
    },
    body: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 400,
    },
    callout: {
      fontSize: '13px',
      fontWeight: 510,
      lineHeight: '15px',
    },
    subHeadline1: {
      fontSize: '15px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    subHeadline2: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    footNote: {
      fontSize: '12px',
      lineHeight: '13px',
      fontWeight: 400,
    },
    caption1: {
      fontSize: '10px',
      lineHeight: '13px',
      fontWeight: 400,
    },
    caption2: {
      fontSize: '10px',
      lineHeight: '13px',
      fontWeight: 510,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          headline2: 'h2',
          headline: 'h5',
          body: 'span',
          callout: 'span',
          subHeadline1: 'span',
          subHeadline2: 'span',
          footNote: 'span',
          caption1: 'span',
          caption2: 'span',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'greyed' },
          style: {
            color: 'grey',
            border: '1px solid grey',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '14px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '14px',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          paddingLeft: '5px',
          paddingRight: '5px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
        },
        formHelperText: {
          marginLeft: '0px !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '14px',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default muiTheme;
