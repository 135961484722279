import { MouseEvent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import _noop from 'lodash/noop';
import { useTruncateText } from 'Hooks/useTruncateText';

import { TypographyVariant } from 'constants/general';

interface TruncatedTextProps {
  text: string;
  maxChars?: number;
  className?: string;
  handleClick?: (event?: MouseEvent<HTMLSpanElement>) => void;
  typographyVariant?: TypographyVariant;
}

interface RenderTextProps {
  typographyVariant: TypographyVariant;
  text: string;
  className?: string;
  handleClick?: () => void;
}
const RenderText = ({
  typographyVariant,
  className = '',
  text,
  handleClick = _noop,
}: RenderTextProps) => {
  return typographyVariant ? (
    <Typography
      variant={typographyVariant}
      className={className ? className : ''}
      onClick={handleClick}
    >
      {text}
    </Typography>
  ) : (
    <span className={className} onClick={handleClick}>
      {text}
    </span>
  );
};

function TruncatedTextWithTooltip({
  text,
  maxChars = 30,
  className = '',
  handleClick = () => {},
  typographyVariant,
}: TruncatedTextProps): JSX.Element {
  const truncatedText = useTruncateText(text, maxChars);
  const isTruncated = truncatedText !== text;
  return (
    <Tooltip placement={'top'} title={isTruncated ? <p className='fs-14'>{text}</p> : null} arrow>
      <Box display={'inline'}>
        <RenderText
          text={isTruncated ? truncatedText : text}
          typographyVariant={typographyVariant}
          className={className}
          handleClick={handleClick}
        />
      </Box>
    </Tooltip>
  );
}

export default TruncatedTextWithTooltip;
