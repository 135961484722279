import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const updateWhitelistUrl = '/moo/utility/v1/whitelist';
const getWhitelistServicesUrl = '/moo/utility/whitelist/v1/services';

export const WhitelistApi = createApi({
  reducerPath: 'WhitelistApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    updateWhitelist: builder.mutation({
      query: (request) => {
        return {
          url: updateWhitelistUrl,
          method: 'POST',
          data: request,
        };
      },
    }),
    getWhitelistServices: builder.query({
      query: () => ({
        url: getWhitelistServicesUrl,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data?.payload,
    }),
  }),
});

export const { useUpdateWhitelistMutation, useGetWhitelistServicesQuery } = WhitelistApi;

export default WhitelistApi.reducer;
