import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const updateLearnerStatusUrl = '/moo/intern-application/v1/learner-status';

export const UpdateLearnerStatusApi = createApi({
  reducerPath: 'UpdateLearnerStatusApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    updateLearnerStatus: builder.mutation({
      query: (request) => {
        return {
          url: updateLearnerStatusUrl,
          method: 'POST',
          data: request,
        };
      },
    }),
  }),
});

export const { useUpdateLearnerStatusMutation } = UpdateLearnerStatusApi;

export default UpdateLearnerStatusApi.reducer;
