import { showSnackbar } from 'Redux/Slices/Common/SnackbarSlice';

export const onQueryStartedErrorToast = async (args, { dispatch, queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    // If the query was cancelled, we don't want to show an error
    const errorMessage = error.error?.message || error.error?.data;
    if (errorMessage?.toLowerCase() === 'aborted') return;
    dispatch(
      showSnackbar({
        severity: 'error',
        alertTitle: 'Error',
        message: errorMessage || 'Something went wrong',
      }),
    );
  }
};
