import axios from './index';

export const axiosBaseQuery =
  () =>
  async ({ url, method, body, headers }, api) => {
    const { signal } = api;
    let result = {};
    const requestOptions = {};

    if (headers) {
      requestOptions.headers = headers;
    }
    try {
      if (method === 'POST') {
        result = await axios.post(url, body);
      } else if (method === 'GET') {
        result = await axios.get(url, { params: body, ...requestOptions, signal });
      } else if (method === 'PUT') {
        result = await axios.put(url, body);
      } else if (method === 'PATCH') {
        result = await axios.patch(url, body);
      } else if (method === 'DELETE') {
        result = await axios.delete(url);
      }

      if (result?.status === 200) return { data: result?.data?.data };
      else {
        console.log('response error', result);
        return {
          error: {
            status: false,
            data:
              typeof result?.data?.data?.payload === 'string'
                ? result?.data?.data?.payload
                : result?.data?.data?.message,
            payload: result?.data?.data?.payload,
            errorCode: result?.data?.data?.errorCode || '',
          },
        };
      }
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
