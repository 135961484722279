import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosInstance';
import {
  OfferFetchResponse,
  ReinstateCandidatureParams,
  WithdrawCandidatureParams,
  withdrawCandidatureResponseData,
  InterviewDetailResponse,
  WithdrawInterviewResponseData,
  WithdrawInterviewParams,
  InternDetailResponse,
  OfferFeedbackResponse,
  OfferFeedbackParams,
  ApproveOfferParams,
  ApproveOfferResponse,
  PostFeedbackParams,
  PostFeedbackResponse,
  SendReminderParams,
  SendReminderResponse,
  CandidatureCommonInfoResponse,
  NoteApiResponse,
  NoteCreateApiResponse,
  NoteCreateApiParams,
  EditNoteApiParams,
  EditNoteApiRespoonse,
  DeleteNoteApiParams,
  DeleteNoteApiResponse,
  CreateCandidatureParams,
  CreateCandidatureResponse,
  GetEligibleCompaniesParams,
  EligibleCompanyResponse,
  RejectApplicationReasonsResponse,
  WithdrawApplicationReasonsResponse,
  CheckCandidatureExists,
  checkCandidatureParam,
} from './ManagePlacementsApiTypes';

import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { removeFalsyValuesFromObject } from 'Util/utilities';

const interviewBaseUrl = '/api/v1/internal-service/host-company/interview';
const candidatureBaseUrl = '/api/v1/internal-service/placement';
const internAppicationBaseUrl = '/api/v1/internal-service/intern-application';
const approveOfferUrl = '/api/v1/internal-service/offers/update-offer-status';
const offerReminderUrl = '/api/v1/internal-service/offers/send-reminder';
const interviewListUrl = '/api/v1/internal-service/host-company/interview/list';
const updatePlacementModeURL = '/api/v1/internal-service/pam/applications/v1/placement-mode';

const objectToQueryString = (obj: object) =>
  Object.entries(obj)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}[]=${value
          .map((item) => encodeURIComponent(item))
          .join(',')}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

export const ManagePlacementsApi = createApi({
  reducerPath: 'managePlacementsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [
    'basicInfo',
    'GetInternCandidatures',
    'GetInterviewsList',
    'GetInternList',
    'overview',
    'GetCandidatureNotes',
    'GetInterviewsForCandidate',
    'GetInterviewForCandidate',
    'GetCandidatureCommonInformation',
    'GetOffersForCandidate',
    'GetOfferFeedback',
    'GetInternDetail',
    'GetApplicationRejectReasons',
    'GetApplicationWithdrawReasons',
    'CheckCandidatureExist',
  ],
  endpoints: (builder) => ({
    getInternDetail: builder.query({
      query: ({ internId }) => {
        return { url: `${candidatureBaseUrl}/${internId}/intern`, method: 'GET' };
      },
      providesTags: ['GetInternDetail'],
    }),
    getInternCandidatures: builder.query({
      query: ({ internId, ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString
          ? `${candidatureBaseUrl}/${internId}/candidatures?${queryString}`
          : `${candidatureBaseUrl}/${internId}/candidatures`;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetInternCandidatures'],
    }),
    getInterviewsList: builder.query({
      query: ({ internId, ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString
          ? `${interviewListUrl}/${internId}?${queryString}`
          : `${interviewListUrl}/${internId}`;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetInterviewsList'],
    }),
    getInternList: builder.query({
      query: (filters) => {
        const filterParams = JSON.parse(JSON.stringify(filters));
        if (filters?.ae_ids) {
          const index = filters?.ae_ids?.findIndex((item) => parseInt(item) === 0);
          if (index !== -1) {
            filterParams.has_null_ae = 1;
            filterParams.ae_ids = filterParams.ae_ids?.filter((item) => parseInt(item) !== 0);
          }
        }

        if (!filterParams?.ae_ids?.length) {
          delete filterParams.ae_ids;
        }

        const queryString = filterParams ? objectToQueryString(filterParams) : '';
        const updatedUrl = queryString
          ? `${candidatureBaseUrl}/enrolled/interns?${queryString}`
          : `${candidatureBaseUrl}/enrolled/interns`;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetInternList'],
    }),
    updatePlacementMode: builder.mutation({
      query: (data) => ({
        url: updatePlacementModeURL,
        method: 'PATCH',
        data,
      }),
      onQueryStarted: onQueryStartedErrorToast,
      invalidatesTags: (_, error) => (error ? [] : ['GetInternList']),
    }),
    getCandidatureOverview: builder.query({
      query: ({ candidatureId }) => {
        return { url: `${candidatureBaseUrl}/${candidatureId}`, method: 'GET' };
      },
      providesTags: ['overview'],
    }),
    getInterviewsForCandidate: builder.query({
      query: ({ candidatureId }) => {
        return { url: `${candidatureBaseUrl}/${candidatureId}/interviews`, method: 'GET' };
      },
      providesTags: ['GetInterviewsForCandidate'],
    }),
    getInterviewForCandidate: builder.query<
      InterviewDetailResponse,
      { candidatureId: number; interviewId: number }
    >({
      query: ({ candidatureId, interviewId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/interview/${interviewId}`,
          method: 'GET',
        };
      },
      providesTags: ['GetInterviewForCandidate'],
    }),
    withdrawCandidature: builder.mutation<
      withdrawCandidatureResponseData,
      WithdrawCandidatureParams
    >({
      query: ({ candidatureId, payload }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/cancel`,
          method: 'PATCH',
          data: payload,
        };
      },
      invalidatesTags: [
        'basicInfo',
        'overview',
        'GetInternCandidatures',
        'GetCandidatureCommonInformation',
        'GetInterviewForCandidate',
      ],
    }),
    reinstateCandidature: builder.mutation<
      withdrawCandidatureResponseData,
      ReinstateCandidatureParams
    >({
      query: ({ candidatureId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/reinstatement`,
          method: 'PATCH',
        };
      },
      invalidatesTags: (_, error) =>
        error
          ? []
          : ['basicInfo', 'overview', 'GetInternCandidatures', 'GetCandidatureCommonInformation'],
    }),
    getCandidatureCommonInformation: builder.query<
      CandidatureCommonInfoResponse,
      { candidatureId: string | number }
    >({
      query: ({ candidatureId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/common/information`,
          method: 'GET',
        };
      },
      providesTags: ['basicInfo'],
      transformResponse: (response) => response?.data?.payload,
    }),
    getCandidatureOffers: builder.query<OfferFetchResponse[], { candidatureId: number | string }>({
      query: ({ candidatureId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/offer`,
          method: 'GET',
        };
      },
      transformResponse: (response) => response?.data?.payload,
      providesTags: ['GetOffersForCandidate'],
    }),
    getCandidatureNotes: builder.query<NoteApiResponse, { candidatureId: number | string }>({
      query: ({ candidatureId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/notes`,
          method: 'GET',
        };
      },
      providesTags: ['GetCandidatureNotes'],
    }),
    addCandidatureNote: builder.mutation<NoteCreateApiResponse, NoteCreateApiParams>({
      query: ({ note, candidatureId }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/note`,
          data: {
            note,
          },
          method: 'POST',
        };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetCandidatureNotes']),
    }),
    editCandidatureNote: builder.mutation<EditNoteApiRespoonse, EditNoteApiParams>({
      query: ({ candidatureId, noteId, note }) => {
        return {
          method: 'PATCH',
          url: `${candidatureBaseUrl}/${candidatureId}/note/${noteId}`,
          data: {
            note,
          },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetCandidatureNotes']),
    }),
    deleteCandidatureNote: builder.mutation<DeleteNoteApiResponse, DeleteNoteApiParams>({
      query: ({ candidatureId, noteId }) => {
        return {
          method: 'DELETE',
          url: `${candidatureBaseUrl}/${candidatureId}/note/${noteId}`,
        };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetCandidatureNotes']),
    }),
    createInterview: builder.mutation({
      query: ({ candidatureId, body }) => ({
        url: `${candidatureBaseUrl}/${candidatureId}/interview`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : ['GetInterviewForCandidate', 'GetInterviewsForCandidate'],
    }),
    updateInterview: builder.mutation({
      query: ({ candidatureId, interviewId, body }) => ({
        url: `${candidatureBaseUrl}/${candidatureId}/interview/${interviewId}`,
        method: 'PATCH',
        data: body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : ['GetInterviewForCandidate', 'GetInterviewsForCandidate'],
    }),
    withdrawInterview: builder.mutation<WithdrawInterviewResponseData, WithdrawInterviewParams>({
      query: ({ candidatureId, interviewId, payload }) => ({
        url: `${candidatureBaseUrl}/${candidatureId}/interview/${interviewId}/cancel`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : ['GetInterviewForCandidate', 'GetInterviewsForCandidate'],
    }),
    getInternAppication: builder.query<InternDetailResponse, { internId: number }>({
      query: ({ internId }) => {
        return { url: `${internAppicationBaseUrl}/intern/${internId}/profile`, method: 'GET' };
      },
    }),
    getCompanyDetails: builder.query({
      query: ({ candidatureId }) => {
        return { url: `${candidatureBaseUrl}/${candidatureId}/company`, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
    createOffer: builder.mutation({
      query: ({ candidatureId, payload }) => ({
        url: `${candidatureBaseUrl}/${candidatureId}/offer`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['basicInfo']),
    }),
    updateOffer: builder.mutation({
      query: ({ candidatureId, offerId, payload }) => ({
        url: `${candidatureBaseUrl}/${candidatureId}/offer/${offerId}`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['GetOffersForCandidate', 'basicInfo']),
    }),
    approveOffer: builder.mutation<ApproveOfferResponse, ApproveOfferParams>({
      query: ({ offerId }) => {
        return {
          url: approveOfferUrl,
          data: {
            offer_id: offerId,
            status_key: 0,
          },
          method: 'POST',
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetOffersForCandidate', 'basicInfo']),
    }),
    acceptOffer: builder.mutation<ApproveOfferResponse, ApproveOfferParams>({
      query: ({ offerId }) => {
        return {
          url: approveOfferUrl,
          data: {
            offer_id: offerId,
            status_key: 3,
          },
          method: 'POST',
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetOffersForCandidate', 'basicInfo']),
    }),
    getFeedback: builder.query<OfferFeedbackResponse, OfferFeedbackParams>({
      query: ({ offerId, candidatureId }) => {
        if (!(offerId && candidatureId)) {
          return null;
        }
        return {
          method: 'GET',
          url: `${candidatureBaseUrl}/${candidatureId}/offer/${offerId}/feedback`,
        };
      },
      providesTags: ['GetOfferFeedback'],
    }),
    postFeedback: builder.mutation<PostFeedbackResponse, PostFeedbackParams>({
      query: ({ offerId, candidatureId, feedback }) => {
        return {
          url: `${candidatureBaseUrl}/${candidatureId}/offer/${offerId}/feedback`,
          data: {
            feedback,
          },
          method: 'POST',
        };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetOfferFeedback', 'GetOffersForCandidate']),
    }),
    sendOfferReminder: builder.mutation<SendReminderResponse, SendReminderParams>({
      query: ({ internId }) => {
        return {
          url: offerReminderUrl,
          data: {
            intern_id: internId,
          },
          method: 'POST',
        };
      },
    }),
    getEligibleCompanies: builder.query<EligibleCompanyResponse, GetEligibleCompaniesParams>({
      query: ({ internId, query }) => {
        return {
          url: `${candidatureBaseUrl}/${internId}/eligible/entities?${query}`,
          method: 'GET',
        };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
    createCandidature: builder.mutation<CreateCandidatureResponse, CreateCandidatureParams>({
      query: ({ internId, hostCompanyId, payload }) => ({
        url: `${candidatureBaseUrl}/${internId}/${hostCompanyId}/candidature`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : ['GetInternCandidatures', 'GetInternList', 'GetCandidatureCommonInformation'],
    }),
    rejectApplicationReasons: builder.query<RejectApplicationReasonsResponse, object>({
      query: () => {
        return { url: `${internAppicationBaseUrl}/application-reject-reasons`, method: 'GET' };
      },
      providesTags: ['GetApplicationRejectReasons'],
      transformResponse: (response) => response?.data?.payload,
    }),
    withdrawApplicationReasons: builder.query<WithdrawApplicationReasonsResponse, object>({
      query: () => {
        return { url: `${internAppicationBaseUrl}/application-withdraw-reasons`, method: 'GET' };
      },
      providesTags: ['GetApplicationWithdrawReasons'],
      transformResponse: (response) => response?.data?.payload,
    }),
    checkCandidature: builder.query<CheckCandidatureExists, checkCandidatureParam>({
      query: ({ applicationId, internId, hcId }) => {
        return {
          url: `${interviewBaseUrl}/check-candidature/${applicationId}/${internId}/${hcId}`,
          method: 'GET',
        };
      },
      providesTags: ['CheckCandidatureExist'],
      transformResponse: (response) => response?.data?.payload,
    }),
  }),
});

export const {
  useGetInternCandidaturesQuery,
  useGetInterviewsListQuery,
  useGetInternListQuery,
  useGetCandidatureOverviewQuery,
  useGetInterviewsForCandidateQuery,
  useGetInterviewForCandidateQuery,
  useWithdrawCandidatureMutation,
  useReinstateCandidatureMutation,
  useGetCandidatureCommonInformationQuery,
  useGetCandidatureOffersQuery,
  useGetCandidatureNotesQuery,
  useAddCandidatureNoteMutation,
  useEditCandidatureNoteMutation,
  useDeleteCandidatureNoteMutation,
  useCreateInterviewMutation,
  useUpdateInterviewMutation,
  useWithdrawInterviewMutation,
  useGetInternAppicationQuery,
  useGetCompanyDetailsQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useApproveOfferMutation,
  useGetFeedbackQuery,
  usePostFeedbackMutation,
  useSendOfferReminderMutation,
  useGetInternDetailQuery,
  useGetEligibleCompaniesQuery,
  useCreateCandidatureMutation,
  useRejectApplicationReasonsQuery,
  useWithdrawApplicationReasonsQuery,
  usePrefetch,
  useCheckCandidatureQuery,
  useAcceptOfferMutation,
  useUpdatePlacementModeMutation,
} = ManagePlacementsApi;

export default ManagePlacementsApi.reducer;
