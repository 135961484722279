import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const pushMessageToQueueUrl = '/moo/utility/queue/v1/message';
const getQueueTopicsUrl = '/moo/utility/queue/v1/topics';

export const PushMessageToQueueApi = createApi({
  reducerPath: 'PushMessageToQueueApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    pushMessageToQueue: builder.mutation({
      query: (request) => {
        return {
          url: pushMessageToQueueUrl,
          method: 'POST',
          data: request,
        };
      },
    }),
    getQueueTopics: builder.query({
      query: () => ({
        url: getQueueTopicsUrl,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data?.payload,
    }),
  }),
});

export const { usePushMessageToQueueMutation, useGetQueueTopicsQuery } = PushMessageToQueueApi;

export default PushMessageToQueueApi.reducer;
