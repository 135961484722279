import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getBatchCodeListURL = '/api/v1/internal-service/batch/get';
const validateBatchUrl = '/moo/intern-application/batch/v1/validate';

export const GetBatchCodeListApi = createApi({
  reducerPath: 'batchCodeListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    batchCodeListApi: builder.query({
      query: (data) => {
        const params = new URLSearchParams();

        params.append('group_by', data.group_by);
        const queryString = params.toString();
        return { url: `${getBatchCodeListURL}?${queryString}`, method: 'GET' };
      },
    }),
    validateBatchChange: builder.query({
      query: (data) => {
        const params = new URLSearchParams();

        // Add batch_id
        params.append('batch_id', data.batch_id);

        // Add application_ids as multiple query params
        data.application_ids.forEach((id) => {
          params.append('application_ids', id);
        });

        // Convert to string
        const queryString = params.toString();
        return {
          url: `${validateBatchUrl}?${queryString}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useBatchCodeListApiQuery, useLazyValidateBatchChangeQuery } = GetBatchCodeListApi;

export default GetBatchCodeListApi.reducer;
